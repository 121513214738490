import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AUTH_ROUTE } from '../../../core/routes';
import { QueryParamsService } from '../../../core/services/query-params.service';
import { RolesService } from '../../../core/services/roles.service';
import { AuthService } from '../../auth.service';
import { AuthToken, Config } from '../../../core/interfaces';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-login',
  templateUrl: './login.component.html',
  styleUrls: ['../styles/form.scss']
})
export class LoginComponent implements OnInit {
  private needUri = '';
  private config: Config = environment;
  private fb = inject(FormBuilder);
  private destroyRef = inject(DestroyRef);
  private queryParamsService = inject(QueryParamsService);
  private authService = inject(AuthService);
  private router = inject(Router);

  roles = inject(RolesService);
  form: FormGroup;
  isLoading = false;
  readonly authRoute = AUTH_ROUTE;
  showPassword = false;

  ngOnInit(): void {
    this.createForm();
    /**
     * Get url for return after auth
     */
    const queryParams = this.queryParamsService.get();
    if (queryParams[`return`]) {
      this.needUri = queryParams[`return`];
    }

    /**
     * Auto login after redirect from landing
     */
    if (Object.keys(queryParams).indexOf('email') !== -1 && Object.keys(queryParams).indexOf('password') !== -1) {
      this.form.controls.username.setValue(window.atob(queryParams[`email`]));
      this.form.controls.password.setValue(window.atob(queryParams[`password`]));
      this.queryParamsService.add(['email', 'password'], ['', '']);
      this.submit();
    }
  }

  createForm() {
    this.form = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      grant_type: 'password',
      client_id: this.config.client_id,
      client_secret: this.config.client_secret
    });
  }

  submit(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.authService
      .authenticate(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (token: AuthToken) => {
          this.isLoading = false;
          this.authService.setToken(token);
          this.router.navigateByUrl(this.needUri);
        },
        error: () => {
          this.isLoading = false;
          this.authService.setToken(null);
        }
      });
  }

  isInvalid(control: AbstractControl): boolean {
    return control.invalid && (control.dirty || control.touched);
  }
}
