<adxad-loader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>
<form
  [formGroup]="form"
  class="authorization__form"
  *ngIf="!isLoading"
>
  <fieldset class="form-fieldset">
    <legend class="form-legend">{{ 'passwordReset' | transloco }}</legend>

    <div class="form-field">
      <input
        class="form-input"
        placeholder="{{ 'password' | transloco }}"
        formControlName="password"
        [class.error]="
          form.get('password').invalid && form.get('password').touched
        "
        [type]="showPassword ? 'text' : 'password'"
      />

      <span
        (click)="showPassword = !showPassword"
        class="material-icons --prefix --password-visibility"
      >
        {{ !showPassword ? 'visibility_off' : 'visibility' }}
      </span>
      <adxad-show-errors [control]="form.get('password')"></adxad-show-errors>
    </div>

    <div class="form-field">
      <input
        class="form-input"
        placeholder="{{ 'confirmPassword' | transloco }}"
        formControlName="password2"
        [class.error]="
          form.get('password2').invalid && form.get('password2').touched
        "
        [type]="showPassword ? 'text' : 'password'"
      />

      <span
        (click)="showPassword = !showPassword"
        class="material-icons --prefix --password-visibility"
      >
        {{ !showPassword ? 'visibility_off' : 'visibility' }}
      </span>
      <adxad-show-errors [control]="form.get('password2')"></adxad-show-errors>
    </div>

    <button
      class="form-btn"
      type="submit"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      {{ 'resetYourPassword' | transloco }}
    </button>
  </fieldset>
</form>
