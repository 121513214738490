import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AdxadModal } from '../../../ui/modules/modal/modal.service';
import { PromotionTermsDialogComponent } from '../promotion-terms-dialog/promotion-terms-dialog.component';
import { TranslocoService } from '@jsverse/transloco';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth.service';
import { AuthToken, Config } from '../../../core/interfaces';
import { AlertsComponent } from '../../../ui/components/alerts/alerts.component';
import { RolesService } from '../../../core/services/roles.service';
import { GlobicaService } from '../../../core/services/globica.service';
import { takeUntil } from 'rxjs/operators';
import { IsMobileService } from '../../../core/services/is-mobile.service';
import { environment } from '../../../../environments/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'adxad-reg',
  templateUrl: './reg.component.html',
  styleUrls: ['../styles/form.scss']
})
export class RegComponent implements OnInit {
  public form: UntypedFormGroup;
  public isLoading = false;
  public promoCode;

  private queryParams;
  private config: Config = environment;
  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private modal: AdxadModal,
    public translate: TranslocoService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private alerts: AlertsComponent,
    public roles: RolesService,
    private router: Router,
    private globicaService: GlobicaService,
    public checkUserAgent: IsMobileService
  ) {}

  ngOnInit(): void {
    this.promoCode = this.route.snapshot.paramMap.get('code');
    this.queryParams = this.route.queryParams['value'];
    this.createForm();
  }

  /**
   * Create reactive form
   */
  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      code: [null, [Validators.required]],
      promoCode: this.promoCode ? this.promoCode : ''
    });

    const referral = this.route.queryParams['value'].referral;

    if (referral) {
      this.form.addControl('referrerToken', this.fb.control(referral));
    }

    if (this.checkUserAgent.isMobile) {
      this.form.addControl('firstName', this.fb.control('', [Validators.required]));
      this.form.addControl('lastName', this.fb.control('', [Validators.required]));
    }
  }

  /**
   * Open modal with promotion terms
   */
  openPromotionTerms(): void {
    this.modal.open(PromotionTermsDialogComponent, {
      width: '600px'
    });
  }

  authenticate() {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const loginRequest = {
      username: this.form.value.email,
      password: this.form.value.password,
      grant_type: 'password',
      client_id: this.config.client_id,
      client_secret: this.config.client_secret
    };

    this.authService
      .authenticate(loginRequest)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (token: AuthToken) => {
          this.isLoading = false;
          this.authService.setToken(token);
          this.router.navigateByUrl('/');
        },
        error: () => this.authService.setToken(null)
      });
  }

  submit() {
    const request = this.form.value;
    this.isLoading = true;

    this.authService
      .register(request)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: response => {
          if (response.status === 'USER CREATED') {
            this.isLoading = false;
            this.globicaService.trackGoals(475);
            this.globicaService.trackAny('params', {
              user_member_id: response.id
            });
            this.alerts.success('Publisher registered', 3000);
            this.authenticate();
          }
        },
        error: () => (this.isLoading = false)
      });
  }
}
