import { Component, HostBinding, inject, ViewChild } from '@angular/core';
import { IsMobileService } from '../../../core/services/is-mobile.service';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslocoService } from '@jsverse/transloco';
import { RolesService } from '../../../core/services/roles.service';

@Component({
  selector: 'adxad-ssp-public-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  checkUserAgent = inject(IsMobileService);
  transloco = inject(TranslocoService);
  roles = inject(RolesService);

  @HostBinding('class') hostClass = this.checkUserAgent.isMobile ? 'authorization --mobile' : 'authorization --desktop';
  @ViewChild('sidenav') sidenav: MatSidenav;

  today: number = Date.now();
  showSubmenu = false;

  closeSidenav() {
    this.sidenav.close();
  }

  toggleSubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }
}
