<ng-container
  [ngTemplateOutlet]="
    checkUserAgent.isMobile ? isMobileView : isDesktopView
  "
></ng-container>

<ng-template #isMobileView>
  <mat-sidenav-container (backdropClick)="closeSidenav()">
    <mat-sidenav
      #sidenav
      disableClose
      position="end"
      [fixedInViewport]="true"
    >
      <div class="sidenav-header">
        <img
          class="sidenav-header__logo"
          src="/assets/images/logo_ad.svg"
          alt=""
        />
        <img
          src="/assets/images/svg/close.svg"
          alt=""
          (click)="closeSidenav()"
        />
      </div>
      <nav class="sidenav__navigation">
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/"
        >
          {{ 'homepage' | transloco }}
        </a>
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/advertisers"
        >
          {{ 'forAdvertisers' | transloco }}
        </a>
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/publishers"
        >
          {{ 'forPublishers' | transloco }}
        </a>
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/team"
        >
          {{ 'about' | transloco }}
        </a>
        <div
          class="sidenav__navigation-item"
          (click)="toggleSubmenu()"
        >
          {{ 'help' | transloco }}
        </div>
        <div
          class="submenu"
          *ngIf="showSubmenu"
        >
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{ transloco.getActiveLang() }}/help-trade"
          >
            – {{ 'tradeDeskHelp' | transloco }}
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{ transloco.getActiveLang() }}/help"
          >
            – {{ 'spec' | transloco }}
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{ transloco.getActiveLang() }}/faq"
          >
            – FAQ
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/guid-advertisers"
          >
            – {{ 'guidelinesForAdvertisers' | transloco }}
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/guid-publishers"
          >
            – {{ 'guidelinesForPublishers' | transloco }}
          </a>
          <a
            class="sidenav__navigation-item"
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/conversions-import-service"
          >
            – {{ 'conversionsImportService' | transloco }}
          </a>
        </div>

        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/contacts"
        >
          {{ 'contactUs' | transloco }}
        </a>
        <a
          class="sidenav__navigation-item"
          href="https://adxad.com/{{ transloco.getActiveLang() }}/login"
        >
          {{ 'signIn' | transloco }}
        </a>
      </nav>
    </mat-sidenav>
    <mat-sidenav-content>
      <header class="sidenav-content__header">
        <img
          class="sidenav-content__header-logo"
          src="/assets/images/logo_ad.svg"
          alt=""
        />

        <div class="sidenav-content__header-actions">
          <adxad-language-picker localesListPosition="right"></adxad-language-picker>
          <img
            src="/assets/images/svg/menu.svg"
            alt=""
            (click)="sidenav.open()"
          />
        </div>
      </header>

      <div class="sidenav-content__body">
        <router-outlet></router-outlet>
      </div>

      <footer class="sidenav-content__footer">
        <a
          href="mailto:services@adxad.com"
          class="contact-email"
        >
          services&#64;adxad.com
        </a>

        <div class="copyright">
          <p>© {{ today | date: 'yyyy' }} ADxAD. All Rights Reserved.</p>
        </div>

        <nav class="sidenav-content__footer-nav">
          <a href="https://adxad.com/{{ transloco.getActiveLang() }}/publishers"> {{ 'forPublishers' | transloco }} </a>
          <a
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/advertisers"
          >
            {{ 'forAdvertisers' | transloco }}
          </a>
          <a href="https://adxad.com/{{ transloco.getActiveLang() }}/team"> {{ 'about' | transloco }} </a>
          <a href="https://adxad.com/{{ transloco.getActiveLang() }}/contacts"> {{ 'support' | transloco }} </a>
          <a
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/privacy-policy"
          >
            {{ 'privacyPolicy' | transloco }}
          </a>
          <a href="https://adxad.com/{{ transloco.getActiveLang() }}/terms"> {{ 'termsConditions' | transloco }} </a>
          <a
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/guid-advertisers"
          >
            {{ 'guidelinesForAdvertisers' | transloco }}
          </a>
          <a
            href="https://adxad.com/{{
              transloco.getActiveLang()
            }}/guid-publishers"
          >
            {{ 'guidelinesForPublishers' | transloco }}
          </a>
        </nav>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</ng-template>

<ng-template #isDesktopView>
  <div class="intro">
    <div class="intro__logo">
      <img
        src="/assets/images/logo_ad.svg"
        alt="ADxAD SSP"
      />
    </div>
    <div class="intro__placement">
      <img
        src="/assets/images/intro-bg.svg"
        alt="Professional media buying team"
        class="intro__img"
      />
    </div>
    <h1 class="intro__title">{{ 'startNowPublishingToProfit' | transloco }}</h1>
  </div>

  <div class="authorization__right">
    <adxad-language-picker localesListPosition="right"/>
    <router-outlet/>
  </div>
</ng-template>
