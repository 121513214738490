import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './components/layout/layout.component';
import { LoginComponent } from './components/login/login.component';
import { PasswordResetRequestComponent } from './components/password-reset-request/password-reset-request.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { PromotionTermsDialogComponent } from './components/promotion-terms-dialog/promotion-terms-dialog.component';
import { RegComponent } from './components/reg/reg.component';
import { GuiModule } from '../gui/gui.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { environment } from '../../environments/environment';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { routes } from './routes';

const ENTRY_COMPONENTS = [
  LayoutComponent,
  LoginComponent,
  PasswordResetRequestComponent,
  PasswordResetComponent,
  PromotionTermsDialogComponent,
  RegComponent
];

@NgModule({
  declarations: [...ENTRY_COMPONENTS],
  imports: [
    CommonModule,
    GuiModule,
    RouterModule,
    ReactiveFormsModule,
    TranslocoModule,
    MatSidenavModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    RouterModule.forChild(routes)
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.sitekey
      } as RecaptchaSettings
    }
  ]
})
export class AuthModule {}
