import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AUTH_ROUTE } from '../../../core/routes';
import { AuthService } from '../../auth.service';
import { AlertsComponent } from '../../../ui/components/alerts/alerts.component';

@Component({
  selector: 'adxad-password-reset-request',
  templateUrl: './password-reset-request.component.html',
  styleUrls: ['../styles/form.scss', './password-reset-request.component.scss']
})
export class PasswordResetRequestComponent implements OnInit {
  form: UntypedFormGroup;
  isSuccess = false;
  readonly authRoute = AUTH_ROUTE;

  constructor(
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    public alerts: AlertsComponent
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  submit(): void {
    this.authService.resetPasswordRequest(this.form.value).subscribe(result => {
      if (result.status === 'OK') {
        this.isSuccess = true;
      }
    });
  }
}
