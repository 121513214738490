import { Component } from '@angular/core';
import { AdxadModalRef } from '../../../ui/modules/modal/modal-ref';

@Component({
  selector: 'adxad-promotion-terms-dialog',
  templateUrl: './promotion-terms-dialog.component.html',
  styleUrls: ['./promotion-terms-dialog.component.scss']
})
export class PromotionTermsDialogComponent {
  constructor(private modalRef: AdxadModalRef) {}

  /**
   * Close modal
   * if submit == true, grid will reload
   *
   * @param {boolean} submit
   */
  closeModal(submit?: boolean): void {
    this.modalRef.close({ submit: submit });
  }
}
