@if (isLoading) {
  <adxad-loader [diameter]="40"/>
} @else {
  <form
    [formGroup]="form"
    class="authorization__form"
    *transloco="let t"
  >
    <fieldset class="form-fieldset">
      <legend class="form-legend">
        <span class="title"> {{ t('welcomeBack') }} </span>
      </legend>

      <div class="form-field">
        <input
          class="form-input form-input--suffix form-input--prefix"
          placeholder="{{ t('login') }}"
          formControlName="username"
          [class.error]="isInvalid(form.get('username'))"
          type="text"
        />
        <span
          class="material-icons --prefix"
          [class.--is-valid]="form.get('username').valid"
        >
        {{ form.get('username').valid ? 'check' : 'mail_outline' }}
      </span>
        <adxad-show-errors [control]="form.get('username')"/>
      </div>

      <div class="form-field">
        <input
          class="form-input"
          placeholder="{{ t('password') }}"
          formControlName="password"
          [class.error]="isInvalid(form.get('password'))"
          [type]="showPassword ? 'text' : 'password'"
        />
        <span
          (click)="showPassword = !showPassword"
          class="material-icons --prefix --password-visibility"
        >
        {{ !showPassword ? 'visibility_off' : 'visibility' }}
      </span>
        <adxad-show-errors [control]="form.get('password')"/>
      </div>

      <div class="links">
        <span> {{ t('passwordResetRequest') }} </span>
        <span
          class="links__item"
          routerLink="{{ authRoute.resetPassRequest }}"
        >
        {{ t('resetYourPassword') }}
      </span>
      </div>

      <button
        class="form-btn"
        type="submit"
        [disabled]="form.invalid"
        (click)="submit()"
      >
        {{ t('signIn') }}
      </button>

      <div class="links">
        <span> {{ t('dontHaveAnAccount') }} </span>
        <span
          class="links__item"
          routerLink="{{ authRoute.reg }}"
        >
        {{ t('signUp') }}
      </span>
      </div>
    </fieldset>
  </form>
}



