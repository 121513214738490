import { Routes } from '@angular/router';
import { LayoutComponent } from './components/layout/layout.component';
import { AUTH_ROUTE } from '../core/routes';
import { RegComponent } from './components/reg/reg.component';
import { PasswordResetRequestComponent } from './components/password-reset-request/password-reset-request.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { LoginComponent } from './components/login/login.component';

export const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        title: 'Hello again. Welcome back!',
        data: {
          pageType: 'LOGIN_PAGE'
        },
        pathMatch: 'full'
      },
      {
        path: AUTH_ROUTE.reg,
        component: RegComponent,
        title: 'Welcome to SSP!',
        data: {
          pageType: 'TOUR_PAGE',
          pageId: 'pub-registration'
        }
      },
      {
        path: AUTH_ROUTE.promo,
        component: RegComponent
      },
      {
        path: AUTH_ROUTE.resetPassRequest,
        component: PasswordResetRequestComponent,
        title: 'Forgot password?',
        data: {
          pageType: 'PASSWORD_RESET'
        }
      },
      {
        path: AUTH_ROUTE.resetPass,
        component: PasswordResetComponent,
        title: 'Please type your new password'
      }
    ]
  }
];
