<adxad-loader
  [diameter]="40"
  *ngIf="isLoading"
></adxad-loader>

<form
  [formGroup]="form"
  class="authorization__form"
  *ngIf="!isLoading"
>
  <fieldset class="form-fieldset">
    <legend class="form-legend">
      <ng-container
        [ngTemplateOutlet]="
          checkUserAgent.isMobile ? mobileLegend : desktopLegend
        "
      ></ng-container>

      <ng-template #mobileLegend>
        <span class="title"> {{ 'welcomeToADxAD' | transloco }} </span>

        <span class="subtitle"> {{ 'publisher' | transloco | titlecase }} </span>
      </ng-template>

      <ng-template #desktopLegend> {{ 'registration' | transloco }} </ng-template>
    </legend>

    <!-- (start) This fields visible if user agent mobile  -->
    <ng-container *ngIf="checkUserAgent.isMobile">
      <div class="form-field">
        <input
          class="form-input"
          placeholder="Name"
          formControlName="firstName"
          type="text"
          [class.error]="
            form.get('firstName').invalid && form.get('firstName').touched
          "
        />
        <adxad-show-errors [control]="form.controls['firstName']"></adxad-show-errors>
      </div>

      <div class="form-field">
        <input
          class="form-input"
          placeholder="Surname"
          formControlName="lastName"
          type="text"
          [class.error]="
            form.get('lastName').invalid && form.get('lastName').touched
          "
        />
        <adxad-show-errors [control]="form.controls['lastName']"></adxad-show-errors>
      </div>
    </ng-container>
    <!-- (end) This fields visible if user agent mobile  -->

    <p class="form-legend__description">{{ 'pleaseEnterValidEmailYouNeedToAccess' | transloco }}</p>

    <div class="form-field">
      <input
        class="form-input form-input--suffix form-input--prefix"
        placeholder="{{ 'email' | transloco }}"
        formControlName="email"
        [class.error]="form.get('email').invalid && form.get('email').touched"
        type="email"
      />
      <span
        [class.--is-valid]="form.get('email').valid"
        class="material-icons --prefix"
      >
        {{ form.get('email').valid ? 'check' : 'mail_outline' }}
      </span>
      <adxad-show-errors [control]="form.get('email')"></adxad-show-errors>
    </div>

    <div class="form-field">
      <input
        class="form-input"
        placeholder="{{ 'password' | transloco }}"
        formControlName="password"
        [class.error]="
          form.get('password').invalid && form.get('password').touched
        "
        type="password"
      />
      <span
        [class.--is-valid]="form.get('password').valid"
        class="material-icons-outlined --prefix"
      >
        {{ form.get('password').valid ? 'check' : 'lock' }}
      </span>
      <adxad-show-errors [control]="form.get('password')"></adxad-show-errors>
    </div>

    <div class="form-field">
      <input
        class="form-input"
        placeholder="{{ 'promoCode' | transloco }}"
        formControlName="promoCode"
        type="text"
      />
      <span class="material-icons --prefix"> star_outline </span>

      <div
        class="promotion-terms"
        (click)="openPromotionTerms()"
      >
        {{ 'promotionTerms' | transloco }}
      </div>
    </div>

    <re-captcha formControlName="code"></re-captcha>

    <div
      class="terms"
      [innerHTML]="
        'registerTerms'
          | transloco: { locale: '' + translate.getActiveLang() + '' }
      "
    ></div>

    <button
      class="form-btn"
      type="submit"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      {{ 'signUp' | transloco }}
    </button>

    <div
      class="links"
      *ngIf="!checkUserAgent.isMobile"
    >
      <div>{{ 'alreadyHaveAnAccount' | transloco }}</div>
      <div
        class="links__item"
        routerLink="/"
      >
        {{ 'signIn' | transloco }}
      </div>
    </div>
  </fieldset>
</form>
