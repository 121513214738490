<form
  [formGroup]="form"
  class="authorization__form"
  *ngIf="!isSuccess"
>
  <fieldset class="form-fieldset">
    <legend class="form-legend">{{ 'passwordResetRequest' | transloco }}</legend>

    <div class="form-field">
      <input
        class="form-input form-input--suffix form-input--prefix"
        placeholder="{{ 'email' | transloco }}"
        formControlName="email"
        [class.error]="form.get('email').invalid && form.get('email').touched"
        type="email"
        autofocus
      />
      <span
        [class.--is-valid]="form.get('email').valid"
        class="material-icons --prefix"
      >
        {{ form.get('email').valid ? 'check' : 'mail_outline' }}
      </span>
      <adxad-show-errors [control]="form.get('email')"></adxad-show-errors>
    </div>

    <p class="notice">{{ 'wellSendYouLetterWithResetLinkOnYourEmail' | transloco }}</p>

    <button
      class="form-btn"
      type="submit"
      [disabled]="form.invalid"
      (click)="submit()"
    >
      {{ 'requestPasswordReset' | transloco }}
    </button>

    <div class="links">
      <div>{{ 'alreadyHaveAnAccount' | transloco }}</div>
      <div
        class="links__item"
        routerLink="/"
      >
        {{ 'signIn' | transloco }}
      </div>
    </div>

    <div class="links">
      <span> {{ 'dontHaveAnAccount' | transloco }} </span>
      <span
        class="links__item"
        routerLink="../{{ authRoute.reg }}"
      >
        {{ 'signUp' | transloco }}
      </span>
    </div>
  </fieldset>
</form>

<p
  class="notice"
  *ngIf="isSuccess"
>
  {{ 'passwordResetting' | transloco }}
</p>
